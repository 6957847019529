<!--

插件依赖 element-ui
线上客Saas组件库 省市区多选择器 --- 2020.09.28  
 -->


<template>
  <div id="xskCascader">
    <el-cascader placeholder="请选择省市信息" size="small" class="cascader" :options="options" v-model="city" :props="props" :show-all-levels="is_levels"
      clearable @change="confirm"></el-cascader>
  </div>
</template>

<script>
  import {
    eltips
  } from "@/util/util.js";
  export default {
    name: "xskCascader",
    data() {
      return {
        props: {
          multiple: false,
          value: "label",
          emitPath: true,
          checkStrictly: false,
        },
        options: [],
        // city: [],
      };
    },
    props: {
      is_levels: {
        type: Boolean,
        default: true,
      },
	  city: {
	    type: [String,Array]
	  },
      values: {
        type: Array,
        default: () => [],
      },
      not_area: {
        type: Boolean,
        default: true,
      },
	  areaShow:{
		  type: Boolean,
		  default: true,
	  }
    },
    watch: {
      values(val) {
        this.city = val;
        console.log("watch val", val);
      },
    },
    created() {
      let that = this;
      this.axios.get("/api/common/get_area_tree").then((res) => {
        this.options = res.data;
        this.options.forEach((e, i) => {
          e.label = e.name;
          (e.value = e.id), (e.children = e.children);
          e.children.forEach((e1, i1) => {
            e1.label = e1.name;
            (e1.value = e1.id);
			if(that.areaShow){
				 e1.children = e1.children;
				 if(that.areaShow){
					 if(e1.children){
						 e1.children.forEach((e2, i2) => {
						   e2.label = e2.name;
						   e2.value = e2.id;
						   // if (e2.name.indexOf("区") != -1 && that.not_area) {
						   //   e2.disabled = true;
						   // }
						 });
					 }
				 	
				 }
			}else{
				e1.children = null
			}
			
			
           
          });
		  
        });
        if (this.values) {
			console.log("this.city = this.values;")
          this.city = this.values;
        }
      });
    },
    methods: {
		setCity(){
			this.city = ''
		},
      confirm(e) {
		  console.log("09",e)
        this.$emit("confirm", this.city);
      },
    },
  };
</script>

<style>
  .el-cascader-panel {
    height: 300px;
  }

  .el-cascader-menu__wrap {
    height: 304px;
  }

  #xskCascader .cascader {
    width: 100%;
  }
</style>