import router from './index.js'
import store from '../store/index.js'
import { resetRouter } from '@/router/index.js'
import { adminroutes } from './admin.js'
import { agentroutes } from './agent.js'
import { changeroute } from './routerPermission.js'
import { tim, imInit, IMlogin, TIM } from '@/util/im.js';

// ,'/apply/wechat','/apply/login'
const whitelist = [
	'/login',
	'/carlogin',
	'/carRowLogin',
	'/apply/wechat', '/apply/login',
	'/adminlogin',
	'/arealogin',
	'/agreement',
	'/userlogin',
	'/cleanlogin',
	'/tescologin',
	'/foodlogin',
	'/recruitlogin',
	'/recruitlogins',
	'/parklogins',
	'/shoplogin',
	'/servicelogin',
	'/easylogin',
	'/sharinglogin',
	'/retaillogin',
	'/orangelogin',
	'/psychologicalLogin',
	'/gallerylogin',
	'/shopMlogin',
	'/agentlogin',
	'/index/createStore',
	'/index/createStore_user',
	'/register',
	'/forget'
	
]
const channelpath = ['/makou/application/index', '/channel/wechatmnp']

// im初始化角色表
const role = [83, 80]

router.beforeEach((to, from, next) => {
	let token = sessionStorage.getItem('token')
	let routes = sessionStorage.getItem('routes') //原始路由
	let pluginroute = sessionStorage.getItem('pluginroute') //插件路由
	let pluginid = sessionStorage.getItem('pluginid') //插件id
	to.meta.keepAlive && store.commit("addIncludeAlive", to.name)
	console.log("routes",JSON.parse(routes))
	if (to.path == '/') {
		next({ path: "/login" });
		return
	}

	let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
	let IMtoken = sessionStorage.getItem('IMtoken')

	if (IMtoken && userinfo.roles.length > 0 && store.state.ImStatus.imlogin == false) {

		console.log(store.state.ImStatus.imlogin, '---store')

		let is_role = role.findIndex(item => {
			return item == userinfo.roles[0].id
		})

		if (is_role != -1) {
			imInit(1400635745, 0)

		}
	}


	if (token && routes) {

		let routeadds = store.state.routeadds
		console.log("routeadds",routeadds)
		if (routeadds == 0) {
			//判断是否存在插件路由且页面未停留在 应用或渠道页面
			if (pluginroute && channelpath.indexOf(to.path) == -1) {
				router.addRoutes(changeroute(JSON.parse(pluginroute)))
				store.commit('pluginrouteadd', pluginid)
			} else {
				//不存在插件路由 直接加载原始路由 并组合路由 
				router.addRoutes(changeroute(JSON.parse(routes)))
			}
			next({ ...to, replace: true })
		} else {
			next()
		}
	} else {
		if (whitelist.indexOf(to.path) != -1) {
			next()
		} else {
			next({ path: "/login" });
			// if (sessionStorage.getItem('guard_info')) {
			// 	let guard_info = JSON.parse(sessionStorage.getItem('guard_info'))
			// 	//跳转到指定后台登录页
			// 	switch (guard_info.guard_name) {
			// 		default:
			// 			if (type == 0) {
			// 				that.$router.replace({ path: "/adminlogin" })
			// 			} else {
			// 				that.$router.replace({ path: "/login" })
			// 			}
			// 		case 'periphery_admin_user': //联联周边游分后台
			// 			next({ path: "userlogin?name=" + guard_info.name + "&type=" + guard_info.type + "&shop_id=" + guard_info.shop_id + "&guard_name=periphery_admin_user" });
			// 			break;
			// 		case 'periphery_admin_user': //代理后台
			// 			that.$router.replace({ path: "/agentlogin" }) 
			// 		break;
			// 	}
			// } else {
			// 	if (store.state.is_apply) {
			// 		next({ path: "/apply/login" });
			// 	} else {
			// 		next({ path: "/login" });
			// 	}
			// }
		}
	}
});
